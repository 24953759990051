@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #intro,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .intro h1 {
    font-size: 10vw; /* Adjust this value as needed */
  }
  .navbar-header {
    padding-bottom: 6px;
  }
}
@media screen and (min-width: 401px) and (max-width: 500px) {
  .intro h1 {
    font-size: 10vw; /* Adjust this value as needed */
  }
  .navbar-header {
    padding-bottom: 6px;
  }
}
@media screen and (max-width: 560px) {
  .intro h1 {
    font-size: 10vw; /* Adjust this value as needed */
  }
  .navbar-toggle {
    margin-top: 18px;
  }

  .navbar-header {
    padding-bottom: 6px;
    display: flex;
    align-content: center;
    height: fit-content;
  }
}
